var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"d-flex justify-content-end mb-5 mr-1"},[_c('div',{staticClass:"d-flex",staticStyle:{"gap":"1rem"}},[_c('button',{staticClass:"btn btn-info",on:{"click":_vm.addQuestion}},[_c('i',{staticClass:"fa fa-plus"}),_vm._v(" Tambah Soal "+_vm._s(this.vendorId)+" ")]),_c('button',{staticClass:"btn btn-success font-weight-bolder font-size-sm",attrs:{"type":"button"},on:{"click":_vm.saveQuestion}},[_vm._v(" Simpan ")])])]),_c('div',[_c('div',{staticClass:"d-flex justify-content-start mb-5 mr-1"},_vm._l((_vm.listTypeOfQuestion),function(item){return _c('div',{key:item.value,class:`bg-white border col mx-5 rounded-lg ${
          _vm.questionCategories.value == item.value
            ? `border-primary border-2`
            : ''
        }`,attrs:{"role":"button"},on:{"click":function($event){return _vm.changeSelected(item)}}},[_c('div',{staticClass:"text-center mt-2"},[_c('h3',{class:`${
              _vm.questionCategories.value != item.value
                ? 'text-muted'
                : 'text-black'
            } fs-5`},[_vm._v(" "+_vm._s(item.text)+" ")])])])}),0)]),_vm._l((_vm.listSoal),function(i,index){return _c('div',{key:i.collapseId},[_c('div',{directives:[{name:"b-toggle",rawName:"v-b-toggle",value:(i.collapseId),expression:"i.collapseId"}],staticClass:"d-flex justify-content-between card p-3 rounded-top",class:{
        'border-bottom-0': i.isOpen,
      },staticStyle:{"gap":"1rem"}},[_c('div',{staticClass:"d-flex justify-content-between align-items-center"},[_c('span',{staticClass:"text-dark font-weight-bold text-hover-primary font-size-lg"},[_vm._v(" Soal "+_vm._s(index + 1)+" ")]),_c('div',{staticClass:"d-flex",staticStyle:{"gap":"1rem"}},[(i.error)?_c('span',{staticClass:"badge bg-light-danger font-size-sm text-hover-danger d-flex align-items-center"},[_vm._v(" belum lengkap ! ")]):_vm._e(),(_vm.listSoal.length > 1)?_c('button',{staticClass:"btn btn-icon btn-light-danger btn-sm",on:{"click":function($event){return _vm.removeQuestion(i.collapseId)}}},[_c('i',{staticClass:"fa fa-trash"})]):_vm._e()])])]),_c('b-collapse',{attrs:{"accordion":'my-accordion-' + i,"id":i.collapseId}},[_c('b-card',{staticClass:"rounded-top-0 border-top-0"},[_c('SoalForm',{ref:"soalForm",refInFor:true})],1)],1)],1)})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }