<template>
  <div>
    <div class="d-flex justify-content-end mb-5 mr-1">
      <div class="d-flex" style="gap: 1rem">
        <button class="btn btn-info" @click="addQuestion">
          <i class="fa fa-plus"></i> Tambah Soal {{ this.vendorId }}
        </button>
        <button
          type="button"
          class="btn btn-success font-weight-bolder font-size-sm"
          @click="saveQuestion"
        >
          Simpan
        </button>
      </div>
    </div>

    <div>
      <div class="d-flex justify-content-start mb-5 mr-1">
        <div
          @click="changeSelected(item)"
          :class="`bg-white border col mx-5 rounded-lg ${
            questionCategories.value == item.value
              ? `border-primary border-2`
              : ''
          }`"
          role="button"
          v-for="item in listTypeOfQuestion"
          :key="item.value"
        >
          <div class="text-center mt-2">
            <h3
              :class="`${
                questionCategories.value != item.value
                  ? 'text-muted'
                  : 'text-black'
              } fs-5`"
            >
              {{ item.text }}
            </h3>
          </div>
        </div>
      </div>
    </div>

    <div v-for="(i, index) in listSoal" :key="i.collapseId">
      <div
        v-b-toggle="i.collapseId"
        class="d-flex justify-content-between card p-3 rounded-top"
        :class="{
          'border-bottom-0': i.isOpen,
        }"
        style="gap: 1rem"
      >
        <div class="d-flex justify-content-between align-items-center">
          <span
            class="text-dark font-weight-bold text-hover-primary font-size-lg"
          >
            Soal {{ index + 1 }}
          </span>
          <div class="d-flex" style="gap: 1rem">
            <span
              v-if="i.error"
              class="badge bg-light-danger font-size-sm text-hover-danger d-flex align-items-center"
            >
              belum lengkap !
            </span>
            <button
              v-if="listSoal.length > 1"
              @click="removeQuestion(i.collapseId)"
              class="btn btn-icon btn-light-danger btn-sm"
            >
              <i class="fa fa-trash"></i>
            </button>
          </div>
        </div>
      </div>
      <b-collapse :accordion="'my-accordion-' + i" :id="i.collapseId">
        <b-card class="rounded-top-0 border-top-0">
          <SoalForm ref="soalForm" />
        </b-card>
      </b-collapse>
    </div>
  </div>
</template>

<script>
import SoalForm from "./SoalForm.vue";
import {
  CLEAR_SOAL,
  CREATE_SOAL,
  GET_ONE_SOAL,
  SET_ONE_SOAL,
  UPDATE_SOAL,
  GET_ONE_QUESTION,
} from "@/core/services/store/soal.module";
import Swal from "sweetalert2";

export default {
  name: "MultipleSoalForm",
  components: {
    SoalForm,
  },
  data() {
    return {
      listTypeOfQuestion: [
        {
          value: "value",
          text: "Value",
          type: "ices",
        },
        {
          value: "sop&sk",
          text: "SOP & SK",
          type: "default",
        },
        {
          value: "others",
          text: "Others",
          type: "default",
        },
      ],
      listSoal: [
        {
          collapseId: "my-accordion-1",
          isOpen: false,
          data: null,
          error: false,
        },
      ],
      questionCategories: {
        value: "value",
        text: "Value",
        type: "ices",
      },
    };
  },
  mounted() {
    this.$root.$on("bv::collapse::state", (collapseId, isJustShown) => {
      this.accordionState(collapseId, isJustShown);
    });
  },
  methods: {
    toggle(id) {},
    addQuestion() {
      this.listSoal.push({
        collapseId: "my-accordion-" + this.getLastIdAccordion,
        isOpen: false,
        data: null,
        error: false,
      });

      setTimeout(() => {
        var lengthOfComponents = this.$refs.soalForm.length;
        this.$refs.soalForm[lengthOfComponents - 1].changeSelected(
          this.questionCategories
        );
      }, 100);
    },
    removeQuestion(collapedId) {
      Swal.fire({
        title: "Anda yakin akan menghapus soal ?",
        showCancelButton: true,
        confirmButtonText: `Hapus`,
        cancelButtonText: `Batal`,
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          var index = this.listSoal.findIndex(
            (item) => item.collapseId == collapedId
          );
          if (index !== -1) {
            this.listSoal.splice(index, 1);
          } else {
            console.error("Object with key", collapedId, "not found");
          }
        }
      });
    },
    accordionState(collapedId, state) {
      var index = this.listSoal.findIndex(
        (item) => item.collapseId == collapedId
      );

      this.$set(this.listSoal[index], "isOpen", state);
    },
    changeSelected(typeQuestion) {
      let type = typeQuestion.type;

      Swal.fire({
        title: "Anda yakin akan mengubah tipe?",
        showCancelButton: true,
        confirmButtonText: `Ubah ke ${type == "default" ? "SOP&SK" : "Value"}`,
        cancelButtonText: `Batal`,
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          this.questionCategories = typeQuestion;
          this.selected = type;

          this.$refs.soalForm.map((x) => {
            x.changeSelected(typeQuestion);
          });
        }
      });
    },
    async saveQuestion() {
      await Promise.all(
        this.$refs.soalForm.map(async (x, i) => {
          const item = await x.submitForm();
          if (item !== undefined) {
            this.$set(this.listSoal[i], "data", item);
            this.$set(this.listSoal[i], "error", false);
          } else {
            this.$set(this.listSoal[i], "error", true);
          }
        })
      );

      if (this.listSoal.filter((x) => x.error == true).length > 0) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Anda belum melengkapi soal!",
        });

        return;
      }

      const _payload = {
        questions_type: this.questionCategories,
        questions: this.listSoal,
      };

      this.$store
        .dispatch(CREATE_SOAL, _payload)
        .then((res) => {
          Swal.fire({
            title: "Berhasil",
            text: `${res.data.success} Soal Berhasil dibuat`,
            icon: "success",
          }).then((success) => {
            success.value && this.$router.back();
          });
        })
        .catch((e) => {
          Swal.fire({
            title: "Gagal melakukan input",
            text: e.response.message,
            icon: "error",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
        });
    },
  },
  computed: {
    getLastIdAccordion() {
      var collapedId =
        this.listSoal[this.listSoal.length - 1].collapseId.split("-");
      return parseInt(collapedId[collapedId.length - 1]) + 1;
    },
  },
};
</script>
